import React, {useState} from 'react';
import {Box, Button, IconButton, TextField, Typography} from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Markdown from "react-markdown";


const ExerciseWritten = ({exercise}) => {
    console.log("Written: " + exercise);
    const [userAnswer, setUserAnswer] = useState('');
    const [feedback, setFeedback] = useState(false);

    const handleSoundPlay = () => {
        if (exercise && exercise.audio) {
            const audio = new Audio("data:audio/mp3;base64," + exercise.sound);
            audio.play();
        }
    };

    const sanitizeString = (str) => {
        return str
            .replace(/[^\w\s'’]|_/g, "") // Remove all non-word characters except apostrophes
            .replace(/\s+/g, " ")        // Replace multiple spaces with a single space
            .trim()                      // Remove leading and trailing spaces
            .toLowerCase();              // Convert to lower case
    };

    const handleSubmit = () => {
        const sanitizedUserAnswer = sanitizeString(userAnswer);
        const sanitizedExerciseAnswer = sanitizeString(exercise.contentSpoiler);

        if (sanitizedUserAnswer === sanitizedExerciseAnswer) {
            setFeedback(`Correct! Answer is: ${exercise.contentSpoiler}`);
        } else {
            setFeedback(`Incorrect, correct answer is: ${exercise.contentSpoiler}`);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSoundPlay();
            handleSubmit();
        }
    };
    
    const processMarkdown = (text) => {
        return text.replace(/\n/g, "  \n")
    }

    return (
        <Box>
            <Markdown>{processMarkdown(exercise.contentPrimary)}</Markdown>
            {exercise.audio && ( <IconButton onClick={handleSoundPlay}>
                <VolumeUpIcon/>
            </IconButton> )}
            <Box sx={{mt: 2}}>
                <TextField
                    autoFill={false}
                    autoComplete={"off"}
                    label="Your Answer"
                    variant="outlined"
                    value={userAnswer}
                    onChange={(e) => setUserAnswer(e.target.value)}
                    onKeyPress={handleKeyPress}
                    fullWidth
                />
            </Box>
            <Box sx={{mt: 2}}>
                <Button variant="contained" color="primary" onClick={() => {
                    handleSoundPlay();
                    handleSubmit();
                }}>
                    Submit
                </Button>
            </Box>
            {feedback && (
                <Typography
                    variant="h6"
                    sx={{
                        mt: 2,
                        color: feedback.startsWith('Correct') ? 'green' : 'red'
                    }}
                >
                    {feedback}
                </Typography>
            )}
        </Box>
    );
};

export default ExerciseWritten;