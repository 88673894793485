import React, {useState} from 'react';
import {TextField} from "@mui/material";


const ValidatedInput = ({ contentBlock }) => {

    console.log(contentBlock);
    const [inputColor, setInputColor] = useState("info");
    const [helperText, setHelperText] = useState("");
    const [userAnswer, setUserAnswer] = useState("");

    const sanitizeString = (str) => {
        return str
            .replace(/[^\w\s'’]|_/g, "") // Remove all non-word characters except apostrophes
            .replace(/\s+/g, " ")        // Replace multiple spaces with a single space
            .trim()                      // Remove leading and trailing spaces
            .toLowerCase();              // Convert to lower case
    };

    const handleKeyPress = (event) => {
        const sanitizedUserAnswer = sanitizeString(userAnswer);
        const sanitizedCorrectAnswer = sanitizeString(contentBlock.answer)
        if (event.key === 'Enter') {
            if (sanitizedUserAnswer === sanitizedCorrectAnswer) {
                setInputColor("success");
                setHelperText("Correct! " + contentBlock.answer);
            } else {
                setInputColor("error");
                setHelperText(contentBlock.answer);}
        }
    };

    return (
        <>
            <TextField
                autoFill={false}
                autoComplete={"off"}
                label={contentBlock.hint}
                variant="outlined"
                color={inputColor}
                helperText={helperText || ""}
                value={userAnswer || ""}
                onKeyPress={handleKeyPress}
                onChange={(e) => setUserAnswer(e.target.value)}
            />
        </>
    );
};

export default ValidatedInput;