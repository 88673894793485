import React from 'react';
import {useNavigate} from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {useEffect, useState} from 'react';
import axios from 'axios';
import {Box, Button, ListItemButton, MenuItem, Select, TextField} from "@mui/material";


const ExerciseList = () => {
    const endpoint = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [exercises, setExercises] = useState([]);
    const [filters, setFilters] = useState({page: 0, size: 20})



    useEffect(() => {
        const fetchExercises = async () => {
            try {
                const response = await axios.get(`${endpoint}/admin/exercises`, {
                    withCredentials: true,
                    params: filters
                });
                setExercises(response.data);
            } catch (error) {
                console.error('Error fetching exercises:', error);
            }
        };
        fetchExercises();
    }, [filters, endpoint]);

    const handleNavigateToExercise = (id) => {
        navigate(`/exercise/${id}`);
    };

    return (
        <>
            <Box sx={{ textAlign: 'left' }}>

                <Select
                    sx={{ m: 2 }}
                    variant={"standard"}
                    label="Exercise type"
                    value={filters.type || ''}
                    onChange={(e) => setFilters({...filters, type: e.target.value, page: 0})}
                >
                    <MenuItem value="LEARN_WORD">Learn dictionary</MenuItem>
                    <MenuItem value="LEARN_PHRASE">Learn phrase</MenuItem>
                    <MenuItem value="LEARN_PRONUNCIATION">Learn pronunciation</MenuItem>
                    <MenuItem value="GRAMMAR">Grammar exercise</MenuItem>
                    <MenuItem value="GRAMMAR_RULE">Grammar rule</MenuItem>
                    <MenuItem value="LEARN_SYNONYMS">Learn synonyms</MenuItem>
                </Select>

                <Select
                    sx={{ m: 2 }}
                    variant={"standard"}
                    label="Language"
                    value={filters.language || ''}
                    onChange={(e) => setFilters({...filters, language: e.target.value, page: 0})}
                >
                    <MenuItem value="FRENCH">French</MenuItem>
                    <MenuItem value="ENGLISH">English</MenuItem>
                </Select>

                <TextField
                    sx={{ m: 2 }}
                    label="Search term"
                    value={filters.searchTerm || ''}
                    onChange={(e) => setFilters({...filters, searchTerm: e.target.value, page: 0})}
                />


                <Button variant="contained" color="primary" onClick={() => {
                    const nextPage = filters.page - 1;
                    setFilters({...filters, page: nextPage});
                }}
                > Previous </Button>
                <Button variant="contained" color="primary" onClick={() => {
                    const nextPage = filters.page + 1;
                    setFilters({...filters, page: nextPage});
                }}
                > Next </Button>

            </Box>
            <List sx={{ width: '90%' }}>
                {exercises.map((exercise) => (
                    <ListItem button key={exercise.id} >
                        <ListItemText sx={{width: '80%'}} primary={exercise.contentPrimary} onClick={() => handleNavigateToExercise(exercise.id)}></ListItemText>
                        <ListItemButton onClick={() => navigator.clipboard.writeText(exercise.id)}>Copy id</ListItemButton>
                        <ListItemButton href={`/exercise/${exercise.id}`}>Edit</ListItemButton>
                    </ListItem>
                ))}
            </List>
        </>

    );
};
export default ExerciseList;
