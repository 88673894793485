import './App.css';
import {Route, Routes} from "react-router-dom";
import { MainPage } from "./pages/main-page";
import ExerciseList from "./pages/exercise-list";
import ExerciseDetails from "./pages/exercise-details";
import ExerciseAdd from "./pages/exercise-add";
import {NavBar} from "./components/layout/navbar";
import ShowExercisePage from "./pages/show-exercise-page";

function App() {

  return (
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <NavBar/>
          <div style={{ flex: 1, padding: '16px' }}>
              <Routes>
                  <Route path="/" element={<MainPage />} />
                  <Route path="/add" element={<ExerciseAdd />} />
                  <Route path="/exercises" element={<ExerciseList />} />
                  <Route path="/exercise/:id" element={<ExerciseDetails />} />
                  <Route path="/learn/word/FRENCH" element={<ShowExercisePage exerciseType={'LEARN_WORD'} language={'FRENCH'} defaultExerciseType={'REVIEW_CARD'}/>} />
                  <Route path="/learn/grammar/ENGLISH" element={<ShowExercisePage exerciseType={'GRAMMAR'} language={'ENGLISH'} defaultExerciseType={'WRITTEN'} />} />
                  <Route path="/learn/synonyms/ENGLISH" element={<ShowExercisePage exerciseType={'LEARN_SYNONYMS'} language={'ENGLISH'} defaultExerciseType={'REVIEW_CARD'} />} />
                  <Route path="/learn/phrases/FRENCH" element={<ShowExercisePage exerciseType={'LEARN_PHRASE'} language={'FRENCH'} defaultExerciseType={'WRITTEN'} />} />
                  <Route path="/learn/pron/FRENCH" element={<ShowExercisePage exerciseType={'LEARN_PRONUNCIATION'} language={'FRENCH'} defaultExerciseType={'REVIEW_CARD'} />} />
                  <Route path="/learn/word/ENGLISH" element={<ShowExercisePage exerciseType={'LEARN_WORD'} language={'ENGLISH'} defaultExerciseType={'REVIEW_CARD'} />} />
                  <Route path="/learn/phrase/ENGLISH" element={<ShowExercisePage exerciseType={'LEARN_PHRASE'} language={'ENGLISH'} defaultExerciseType={'REVIEW_CARD'} />} />
              </Routes>
          </div>

      </div>
  );
}

export default App;
