import Grid from '@mui/material/Grid2';
import {Button, Card, CardActions, CardContent, Typography} from "@mui/material";

export const MainPage = () => {

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 6, md: 3}}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Words
                        </Typography>
                        <Typography variant="h6" component="div">
                            French
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" href="/learn/word/FRENCH">Learn</Button>
                    </CardActions>
                </Card>
            </Grid>

            <Grid size={{xs: 6, md: 3}}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Grammar
                        </Typography>
                        <Typography variant="h6" component="div">
                            English
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" href="/learn/grammar/ENGLISH">Learn</Button>
                    </CardActions>
                </Card>
            </Grid>

            <Grid size={{xs: 6, md: 3}}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Synonyms
                        </Typography>
                        <Typography variant="h6" component="div">
                            English
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" href="/learn/synonyms/ENGLISH">Learn</Button>
                    </CardActions>
                </Card>
            </Grid>

            <Grid size={{xs: 6, md: 3}}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Phrases
                        </Typography>
                        <Typography variant="h6" component="div">
                            French
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" href="/learn/phrases/FRENCH">Learn</Button>
                    </CardActions>
                </Card>
            </Grid>

            <Grid size={{xs: 6, md: 3}}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Pronunciation
                        </Typography>
                        <Typography variant="h6" component="div">
                            French
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" href="/learn/pron/FRENCH">Learn</Button>
                    </CardActions>
                </Card>
            </Grid>

            <Grid size={{xs: 6, md: 3}}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Words
                        </Typography>
                        <Typography variant="h6" component="div">
                            English
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" href="/learn/word/ENGLISH">Learn</Button>
                    </CardActions>
                </Card>
            </Grid>

            <Grid size={{xs: 6, md: 3}}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Phrase
                        </Typography>
                        <Typography variant="h6" component="div">
                            English
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" href="/learn/phrase/ENGLISH">Learn</Button>
                    </CardActions>
                </Card>
            </Grid>



        </Grid>
    );
}