import React from 'react';
import GrammarExerciseAlternatives from "../grammar-exercise/exercise-alternatives";
import ExerciseFillGaps from "../grammar-exercise/exercise-fill-gaps";
import ExerciseWritten from "../grammar-exercise/exercise-written";
import ExerciseReviewCard from "../grammar-exercise/exercise-review-card";


const ExerciseSelector = ({ exercise, defaultExerciseType }) => {

    const chooseExerciseType = (exercise, defaultExerciseType) => {
        const key = exercise.id + Date.now();
        if (exercise.metadata?.type === 'ALTERNATIVES') {
            return <GrammarExerciseAlternatives key={key} exercise={exercise} />;
        }
        if (exercise.metadata?.type === 'FILL_GAPS') {
            return <ExerciseFillGaps key={key} exercise={exercise} />;
        }
        if (defaultExerciseType === 'WRITTEN') {
            return <ExerciseWritten key={key} exercise={exercise} />;
        }
        if (defaultExerciseType === 'REVIEW_CARD') {
            return <ExerciseReviewCard key={key} exercise={exercise} />;
        }
    }

    return chooseExerciseType(exercise, defaultExerciseType);
};

export default ExerciseSelector;