import React, {useState} from 'react';
import {Box, Button, IconButton, Typography} from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";


const ExerciseReviewCard = ({exercise}) => {
    const [showSpoiler, setShowSpoiler] = useState(false);
    console.log("Review card: " + exercise);

    const handleSoundPlay = () => {
        if (exercise && exercise.audio) {
            const audio = new Audio("data:audio/mp3;base64," + exercise.audio);
            audio.play();
        }
    };

    const handleSubmit = () => {
        setShowSpoiler(true);
    };

    return (
        <Box>
            <Typography variant="h5">{exercise.contentPrimary}</Typography>
            {exercise.audio && (
                <Box>
                    <IconButton onClick={handleSoundPlay}>
                        <VolumeUpIcon/>
                    </IconButton>
                </Box>)}
            <Box sx={{mt: 2}}>
                <Button variant="contained" color="primary" onClick={() => {
                    handleSoundPlay();
                    handleSubmit();
                }}>
                    Show Spoiler
                </Button>
            </Box>
            {showSpoiler && (
                <Typography
                    variant="h6"
                    sx={{
                        mt: 2,
                    }}
                >
                    {exercise.contentSpoiler}
                </Typography>
            )}
            {showSpoiler && exercise.image && <Box component="img" src={`data:image/jpeg;base64,${exercise.image}`}
                                                   alt={exercise.contentPrimary}/>}
        </Box>
    );
};

export default ExerciseReviewCard;