import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Button, Typography, Box, Link} from '@mui/material';
import ExerciseSelector from "../components/exercise/exercise-selector";
import Markdown from "react-markdown";

const ShowExercisePage = ({ exerciseType, language, defaultExerciseType }) => {
    const [exercise, setExercise] = useState(null);
    const [dependentContent, setDependentContent] = useState("");
    const [noExercisesLeft, setNoExercisesLeft] = useState(false);
    const endpoint = process.env.REACT_APP_API_URL; // Ensure you have REACT_APP_API_URL in your .env file

    const fetchExercise = async () => {
        try {
            const response = await axios.get(`${endpoint}/exercises/random/${exerciseType}/${language}`, {
                withCredentials: true
            });

            if (response.status === 204) {
                setNoExercisesLeft(true);
                setExercise(null);
                setDependentContent(null);
            } else {
                setExercise(response.data);
                setNoExercisesLeft(false);
                setDependentContent(null);
            }
        } catch (error) {
            console.error('Error fetching exercise:', error);
        }
    };

    const fetchDependentContent = async (contentId) => {
        try {
            const response = await axios.get(`${endpoint}/exercises/${contentId}`, {
                withCredentials: true
            });
            setDependentContent(response.data.contentPrimary);

        } catch (error) {
            console.error('Error fetching depending content:', error);
        }
    }

    useEffect(() => {
        fetchExercise();
        // eslint-disable-next-line
    }, []);


    const handleComplexity = async (complexity) => {
        try {
            await axios.post(`${endpoint}/exercises/${exercise.id}/complexity1/${complexity}`, null, {
                withCredentials: true
            });
            fetchExercise();
        } catch (error) {
            alert('Error updating exercise complexity');
            console.error(`Error updating exercise complexity to ${complexity}:`, error);
        }
    };

    const handleSchedule = async (count) => {
        try {
            await axios.post(`${endpoint}/exercises/${exerciseType}/${language}/schedule`, null, {
                withCredentials: true,
                params: {
                    count: count
                }
            });
            alert('Exercises scheduled');
        } catch (error) {
            alert('Error scheduling exercises');
        }
    };

    return (
        <Box sx={{ p: 2, textAlign: 'center' }}>
            {noExercisesLeft ? (
                <Typography variant="h6">No exercises left, please come back later</Typography>
            ) : exercise ? (
                <>
                    <ExerciseSelector exercise={exercise} defaultExerciseType={defaultExerciseType} />
                    <Box sx={{ mt: 2 }}>
                        <Button title="You answered correctly with no hesitation"
                            variant="contained" sx={{ backgroundColor: '#e0f7fa', color: '#006064', m: 1 }} onClick={() => handleComplexity('EASY')}>
                            Easy
                        </Button>
                        <Button title="You answered correctly with a little bit of hesitation"
                            variant="contained" sx={{ backgroundColor: '#c8e6c9', color: '#2e7d32', m: 1 }} onClick={() => handleComplexity('GOOD')}>
                            Good
                        </Button>
                        <Button title="Your answer was partially correct, and/or you hesitated a lot"
                            variant="contained" sx={{ backgroundColor: '#fff9c4', color: '#f9a825', m: 1 }} onClick={() => handleComplexity('HARD')}>
                            Hard
                        </Button>
                        <Button title="Your answer was completely incorrect"
                            variant="contained" sx={{ backgroundColor: '#ffcdd2', color: '#c62828', m: 1 }} onClick={() => handleComplexity('AGAIN')}>
                            Again
                        </Button>
                        <Typography
                            variant="body2"
                            sx={{
                                mt: 2,
                                color: 'gray'
                            }}
                        >
                            Exercises: left - {exercise.exercisesLeft} next hour - {exercise.exercisesAvailableNextHour} available to schedule - {exercise.exercisesAvailableToSchedule}
                        </Typography>
                        <Link href={`/exercise/${exercise.id}`}>Edit</Link>
                        {exercise.dependentContent && <Link sx={{ m: 2 }} onClick={() => fetchDependentContent(exercise.dependentContent)}> Show rule</Link>}
                        <Markdown>{dependentContent}</Markdown>
                    </Box>
                </>
            ) : (
                <Typography variant="h6">Loading exercise...</Typography>
            )
            }
            {
                <Box sx={{ mt: 2 }}>
                    <Button size="small" variant="contained" sx={{ m: 1 }} onClick={() => handleSchedule(5)}>
                        Schedule 5
                    </Button>
                    <Button size="small" variant="contained" sx={{ m: 1 }} onClick={() => handleSchedule(20)}>
                        Schedule 20
                    </Button>
                    <Button size="small" variant="contained" sx={{ m: 1 }} onClick={() => handleSchedule(50)}>
                        Schedule 50
                    </Button>
                </Box>
            }
        </Box>
    );
};

export default ShowExercisePage;