import React, {useState} from 'react';
import {Box, Button, Typography} from "@mui/material";


const GrammarExerciseAltenatives = ({ exercise }) => {

    const [buttonColors, setButtonColors] = useState({});

    const handleButtonClick = (index, isRightAnswer) => {
        // Update the specific button's color based on the answer
        setButtonColors((prevColors) => ({
            ...prevColors,
            [index]: isRightAnswer ? "success" : "error"
        }));
    };

    return (
        <>
            <Box>
                {exercise.metadata.content.map((contentPart, index) => (
                    <>
                        {contentPart.type === "TEXT" && (
                            <Typography sx={{m: 1}} display={"inline"} variant="string" component="span">{contentPart.content}</Typography>
                        )}
                        {contentPart.type === "BUTTON" && (
                            <Button sx={{m: 1}} variant="contained" color={buttonColors[index] || "info"}
                                    onClick={() => handleButtonClick(index, contentPart.rightAnswer)}
                            >
                                <Typography textTransform={'none'}>{contentPart.content}</Typography>
                            </Button>
                        )}
                        {contentPart.type === "LINE_BREAK" && (
                            <Box sx={{m: 1}}/>
                        )}
                    </>
                ))}
            </Box>
        </>
    );
};

export default GrammarExerciseAltenatives;