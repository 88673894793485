import React from 'react';
import {Box, Typography} from "@mui/material";
import ValidatedInput from "../molecules/validated-input";

const ExerciseFillGaps = ({ exercise }) => {

    return (
        <>
            <Box>
                {exercise.metadata.content.map((contentPart, index) => (
                    <>
                        {contentPart.type === "TEXT" && (
                            <Typography sx={{m: 1}} display={"inline"} variant="string" component="span">{contentPart.content}</Typography>
                        )}
                        {contentPart.type === "INPUT" && (
                            <ValidatedInput contentBlock={contentPart} />
                        )}
                        {contentPart.type === "LINE_BREAK" && (
                            <Box sx={{m: 1}}/>
                        )}
                    </>
                ))}
            </Box>
        </>
    );
};

export default ExerciseFillGaps;